import { createContext, useCallback, useContext } from 'react';
import { ExternalLinkOpenerPlugin } from 'packages/external-link-opener/plugin';

export const ExternalLinkOpenerContext = createContext<
  ExternalLinkOpenerPlugin
>(null as any);

export const useExternalLinkHandler = (url: string) => {
  const externalLinkHandler = useContext(ExternalLinkOpenerContext);
  const onClick = useCallback(
    (event) => {
      if (event) event.preventDefault();
      externalLinkHandler.openExternalLink(url);
    },
    [externalLinkHandler, url]
  );
  const rel = 'noopener';

  return {
    url,
    onClick,
    rel
  };
};
